import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { ClockIcon, HeartIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import Head from 'next/head';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from 'next/router';
import IndoorBike from '../public/images/home/indoor-bike.svg';
import WorkingOut from '../public/images/home/working-out.svg';
import {
  SelectorIcon,
  CalendarIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import { axiosInstance } from '../helpers';
import dynamic from 'next/dynamic';

const Calendly = dynamic(() => import("../components/Calendly"), {
  ssr: false
});

const features = [
  {
    name: 'Select the company',
    description:
      'Choose from the dropdown menu one of the available companies in your area.',
    icon: SelectorIcon,
    image: 'select-company',
  },
  {
    name: 'See the available calendar',
    description: 'Find the slot and session you like.',
    icon: CalendarIcon,
    image: 'view-the-calendar',
  },
  {
    name: 'Book your slot',
    description: `Click on the book button and reserve your slot.`,
    icon: CheckIcon,
    image: 'book-the-slot',
  },
];

const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  { name: 'Sign out', href: '/api/auth/logout' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const footerNavigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Accessibility', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

function Index({ companies }) {
  const { user, error, isLoading } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (router.query.customerCreationFailure) {
      toast.error('Something went wrong, please try to login again.');
    }
  }, []);

  return (
    <div className="bg-white">
      <Head>
        <title>slotit.io</title>
      </Head>
      <ToastContainer />
      <header className="shadow">
        <div className="flex justify-between items-center mx-auto px-4 h-20">
          <div className="flex justify-between lg:w-0 lg:flex-1">
            <div>
              <Link href="/">
                <a className="flex items-center flex-shrink-0">
                  <ClockIcon
                    className="h-12 w-12 text-indigo-600"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-indigo-600 hidden lg:flex">
                    slotit.io
                  </span>
                </a>
              </Link>
            </div>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {user?.name ? (
              <>
                <Menu as="div" className="ml-3 relative">
                  <div className="flex">
                    <div className="mr-2 hidden sm:block">
                      <Link href="/calendar">
                        <a className="flex items-center bg-white p-1 rounded-full text-indigo-600 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span>Book your slot</span>
                          <ArrowRightIcon
                            className="h-5 w-5 text-indigo-600 ml-2"
                            aria-hidden="true"
                          />
                        </a>
                      </Link>
                    </div>
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex flex-shrink-0 items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <span className="whitespace-nowrap px-2 font-medium text-gray-500 hover:text-gray-900">
                          {user.name}
                        </span>
                        <Image
                          className="inline-block h-9 w-9 rounded-full"
                          src={user.picture}
                          alt={user.name}
                          width="32"
                          height="32"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-50 origin-top-right absolute right-0 mr-24 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
              </>
            ) : (
              // eslint-disable-next-line @next/next/no-html-link-for-pages
              <>
                <a
                  href={process.env.NEXT_PUBLIC_PARTNERS_APP}
                  target="_blank"
                  className="mr-2 sm:mr-5 text-sm sm:text-base p-1 px-2.5 rounded-full text-gray-500 hover:text-gray-400 underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                  rel="noreferrer"
                >
                  For business
                </a>
                <Calendly />
                {/* <a
                  href={`/api/auth/login`}
                  className="p-1 px-2.5 rounded-full text-indigo-500 hover:text-indigo-400 ring-2 ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 mr-3"
                >
                  Login
                </a>
                <a
                  href={`/api/auth/login?signup=true`}
                  className="p-1 px-2.5 rounded-full text-indigo-500 hover:text-indigo-400 focus:outline-none ring-2 ring-indigo-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
                >
                  Signup
                </a> */}
              </>
            )}
          </div>
        </div>
      </header>
      <main>
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 pb-12 sm:pb-24">
          <div className="text-center">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
              <span className="block xl:inline">Instantly book your</span>{' '}
              <span className="block text-indigo-600 xl:inline">
                slot online
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Select the company in your area, see the available calendar, find
              details for the services like training type (Yoga, TRX) and
              reserve your slot online.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link href="/calendar">
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    Get started
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:gap-8 mt-12 md:mt-24">
            {companies.map((company) => (
              <div
                className="bg-white border border-gray-200 shadow overflow-hidden sm:rounded-lg"
                key={company.id}
              >
                <div className="py-10 px-6 text-center rounded-lg xl:px-10">
                  <div className="space-y-6 xl:space-y-10">
                    <img
                      className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                      src={company?.logoUrl}
                      alt={company?.name}
                    />
                    <div className="space-y-2">
                      <div className="font-medium text-lg sm:text-2xl leading-6 space-y-1">
                        <h3 className="text-gray-900">{company?.name}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 sm:py-5 sm:px-6 space-y-4 space-x-4">
                    <Link href={`/calendar?companyAlias=${company?.alias}`}>
                      <a className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Calendar
                      </a>
                    </Link>
                    <Link
                      href={`/companies/${company?.alias}/subscription-plans`}
                    >
                      <a className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Subscription plans
                      </a>
                    </Link>
                  </div>
                  <div className="py-2 sm:py-5 sm:px-6 space-y-4 space-x-4">
                    <a href={`https://${company?.alias}.slotit.io`} target="_blank" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10" rel="noreferrer">
                        View {company?.name}
                      </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="py-12 sm:py-24 bg-gray-100">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <span className="block xl:inline">How it works</span>
              </h2>
            </div>
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {feature.description}
                    {/* <div className="mt-6">
                      <img
                        className="w-80 h-auto"
                        src={`images/home/${feature.image}.png`}
                        alt={'slotit'}
                      />
                    </div> */}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12">
              <div className="rounded-md shadow">
                <Link href="/calendar">
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    View calendar
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex justify-center mt-6 sm:mt-12">
              <WorkingOut className="max-w-sm h-auto sm:max-w-lg" />
            </div>
          </div>
        </div>

        <div className="py-12 sm:py-24 bg-white">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <span className="block xl:inline">Pricing</span>
              </h2>
            </div>
            <div className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="flex justify-center">
                <IndoorBike className="max-w-sm h-auto" />
              </div>
              <div>
                <div className="relative mb-5 md:mb-12">
                  <dt>
                    <CheckIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                      Book your first slot completely free
                    </p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    You don't need to pay anything to book the first slot per
                    company. Usually, the intro training is free of charge so
                    you could take the opportunity and try the session you like
                    - if not you pay when you visit the company.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <CheckIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                      Get access to the subscription plans of each company
                    </p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">
                    Companies offer various different plans that could help you
                    select the one that covers your needs.
                  </dd>
                </div>
                <div>
                  <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12">
                    <div className="rounded-md shadow">
                      <Link href="/companies">
                        <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                          View subscription plans
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="mt-24 bg-gray-900 sm:mt-12">
        <div className="mx-auto max-w-md py-16 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="mt-8 flex justify-center space-x-6">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-center text-base text-gray-400">
            &copy;2022 slotit. All rights reserved.
          </p>
          <div className="mt-4 text-center text-sm text-gray-400 flex justify-center items-center">
            <HeartIcon
              className="h-5 w-5 text-indigo-600 mr-2"
              aria-hidden="true"
            />
            <p>
              powered by <a href={'https://slotit.io'}>slotit.io</a>.{' '}
              <Link href="/privacy-policy">
                <a>Terms of Service & Privacy Policy</a>
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export async function getServerSideProps(context) {
  let companies = [];
  const axiosService = axiosInstance();

  try {
    const { data } = await axiosService.get(`companies/engine/public`);
    companies = data;
  } catch (error) {
    console.log(error);
  }

  return {
    props: {
      companies,
    },
  };
}

export default Index;
